export enum Locale {
  De = "de",
  Fr = "fr",
}

export function supportedLocales(): Locale[] {
  return [Locale.De, Locale.Fr];
}

export const localeCookieName = "Locale";
