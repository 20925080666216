import useCsrf from '~/common/forms/useCsrf';
import { useToast } from '~/common/useToasts';
import { ErrorResponse } from '@shared/app';
import {
  BusinessError,
  error,
  result,
  ResultOrErrorPromise,
} from '@shared/errors';

interface ApiArgs {
  config?: Record<string, object | string>;
  body?: object;
  params?:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined;
}

export default function useApi<T>(
  endpoint: string,
  options?: {
    baseUrl?: string;
    errorHandler?: (
      data: ErrorResponse | Error | undefined,
    ) => BusinessError | null;
  },
): Record<'get' | 'post' | 'put' | 'delete', (args?: ApiArgs) => Promise<T>> {
  const { token } = useCsrf();

  const { baseUrl = '/api/', errorHandler } = options ?? {};

  async function get(args?: ApiArgs): Promise<T> {
    let input = `${baseUrl}${endpoint}`;

    if (args && 'params' in args) {
      input += `?${new URLSearchParams(args.params)}`;
    }

    return fetchBase(input, {
      method: 'GET',
      ...args?.config,
    });
  }

  async function post(args?: ApiArgs): Promise<T> {
    return fetchBase(`${baseUrl}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': token.value,
      },
      method: 'POST',
      ...args?.config,
      body: args ? JSON.stringify(args.body) : undefined,
    });
  }

  async function put(args?: ApiArgs): Promise<T> {
    return fetchBase(`${baseUrl}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': token.value,
      },
      method: 'PUT',
      ...args?.config,
      body: args ? JSON.stringify(args.body) : undefined,
    });
  }

  async function remove(args?: ApiArgs): Promise<T> {
    return fetchBase(`${baseUrl}${endpoint}`, {
      headers: { 'x-csrf-token': token.value },
      method: 'DELETE',
      ...args?.config,
    });
  }

  async function fetchBase(input: string, config: object) {
    return fetch(input, config)
      .then(async (res) => {
        if (res.ok) {
          const contentType = res.headers.get('content-type');

          if (contentType && contentType.includes('application/json')) {
            return res.json();
          }

          if (contentType && contentType.includes('text/html')) {
            return res.text();
          }

          throw new Error(
            `useApi.fetchBase: content type not json, ${contentType} not supported yet}`,
          );
        }
        const body: ErrorResponse = await res.json();

        if (errorHandler) {
          return errorHandler(body);
        } else {
          return handleAppErrorResponse(body);
        }
      })
      .catch((e) => {
        if (errorHandler) {
          return errorHandler(e);
        } else {
          throw new Error(e);
        }
      });
  }

  function handleAppErrorResponse(error: ErrorResponse | undefined) {
    if (!error) {
      useToast({ message: 'Something went wrong', type: 'error' });
      throw new Error('Something went wrong');
    } else {
      let message = '';
      const type = 'error';

      if (error.type === 'error') {
        message = error.message;
      } else if (error.type === 'validation-error') {
        message = error.errors.map((m) => m.messages.join(', ')).join('\n');
      }
      useToast({ message, type });
      throw new Error(message);
    }
  }

  return {
    get,
    post,
    put,
    delete: remove,
  };
}

export function useApiWithErrorHandling<T>(
  endpoint: string,
  _options?: { baseUrl: string },
): Record<
  'get' | 'post' | 'put' | 'delete',
  (args?: ApiArgs) => ResultOrErrorPromise<T>
> {
  const api = useApi<T>(endpoint, {
    ..._options,
    errorHandler: (data: ErrorResponse | Error | undefined) => {
      if (data && !(data instanceof Error) && data.type === 'business-error') {
        return new BusinessError(data.context, data.message);
      }

      // Log unhandled error to console
      console.error(data);

      return new BusinessError(
        { type: 'unknown-error', cause: data },
        'common.error.unknown',
      );
    },
  });

  async function get(args?: ApiArgs): ResultOrErrorPromise<T> {
    return runRequest(() => api.get(args));
  }

  async function put(args?: ApiArgs): ResultOrErrorPromise<T> {
    return runRequest(() => api.put(args));
  }

  async function post(args?: ApiArgs): ResultOrErrorPromise<T> {
    return runRequest(() => api.post(args));
  }

  async function remove(args?: ApiArgs): ResultOrErrorPromise<T> {
    return runRequest(() => api.delete(args));
  }

  async function runRequest(apiRequest: () => Promise<T>) {
    const data = await apiRequest();

    if (data instanceof BusinessError) {
      return error(data);
    }

    return result(data);
  }

  return {
    get,
    put,
    post,
    delete: remove,
  };
}
