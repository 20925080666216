export enum UserRole {
    Admin = 'admin',
    Teacher = 'teacher',
    Editor = 'editor',
}

export function allUserRoles() {
    return [UserRole.Admin, UserRole.Teacher, UserRole.Editor];
}

