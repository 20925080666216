<template>
  <div class="game-config-editor">
    <div class="actions">
      <ButtonPrimary
        type="button"
        class="button"
        :disabled="hasErrors"
        label="Speichern"
        icon-name="check-circle"
        @click="save()"
      />
      <ButtonSecondary
        icon-name="pencil-square"
        label="Formatieren"
        @click="format()"
      />
    </div>
    <div class="schema-editor">
      <div class="types">
        <pre><code>{{ schema.types }}</code></pre>
      </div>
      <div class="editor">
        <div ref="monacoElementRef" class="monaco" />
        <div class="markers">
          <div
            v-for="(marker, index) in markers"
            :key="`marker-${index}`"
            class="marker"
          >
            L{{ marker.startLineNumber }}: {{ marker.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUpdated, ref, toRefs, watch } from 'vue';
import hljs from 'highlight.js/lib/core';
import typescript from 'highlight.js/lib/languages/typescript';
import 'highlight.js/styles/github.css';
import { GameSchemaDto } from '@shared/game';
import ButtonPrimary from '~/common/buttons/ButtonPrimary.vue';
import ButtonSecondary from '~/common/buttons/ButtonSecondary.vue';

const props = defineProps<{
  schema: GameSchemaDto;
  content: string | null;
}>();

// IMPORTANT NOTE: Importing types from monaco-editor causes stuff to be loaded an integrated in build
// Also, causes vite dev to crash regulary
//  -> define types manually :(
interface IStandaloneCodeEditor {
  trigger(arg0: null, arg1: string, arg2: null): unknown;

  dispose(): unknown;

  getValue(): string;
}

interface IMarker {
  startLineNumber: number;
  message: string;
}

// END NOTE

const emit = defineEmits<{
  (e: 'save', content: string): void;
}>();

const monacoElementRef = ref<HTMLElement>();
const { schema, content } = toRefs(props);
const markers = ref<IMarker[]>([]);

// NOTE: storing editor in a reactive variable results in problems
//  @see -> https://github.com/microsoft/monaco-editor/issues/2714
let codeEditor: IStandaloneCodeEditor | null = null;

hljs.registerLanguage('typescript', typescript);

function save() {
  if (codeEditor !== null) {
    emit('save', codeEditor.getValue());
  }
}

function format() {
  if (codeEditor !== null) {
    codeEditor.trigger(null, 'editor.action.formatDocument', null);
  }
}

const hasErrors = computed(() => {
  return markers.value.length > 0;
});

onMounted(() => {
  hljs.highlightAll();
});

onUpdated(() => {
  hljs.highlightAll();
});

async function initMonacoEditor() {
  if (!monacoElementRef.value) {
    return;
  }

  if (codeEditor !== null) {
    codeEditor.dispose();
  }

  const monaco = await import('monaco-editor');

  notifyIfEditorReady();
  initEditorWithSchemaAndContent();

  function onEditorLoaded() {
    console.log('editor content loaded');
    format();
  }

  // NOTE: ONLY WORKS IF THERE IS ALWAYS A VALUE SET INITIALLY! DOES NOT WORK FOR EMPTY EDITORS
  function notifyIfEditorReady() {
    console.log('checking...');
    const $el = monacoElementRef.value?.querySelector(
      '.monaco-scrollable-element.editor-scrollable',
    ) as HTMLElement | null | undefined;
    if ($el?.innerText.trim() === '') {
      console.log('editor not ready....');
      setTimeout(() => notifyIfEditorReady(), 0);
    } else {
      console.log('editor is now ready!', $el?.innerText.trim());
      setTimeout(() => onEditorLoaded(), 0);
    }
  }

  function initEditorWithSchemaAndContent() {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: 'http://myserver/gak.json',
          fileMatch: ['*'],
          schema: JSON.parse(schema.value.schema),
        },
      ],
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    codeEditor = monaco.editor.create(monacoElementRef.value!, {
      value: content.value ?? '{}',
      language: 'json',
    });

    monaco.editor.onDidChangeMarkers(() => {
      markers.value = monaco.editor.getModelMarkers({ owner: 'json' }) ?? [];
    });

    format();
  }
}

watch(monacoElementRef, async () => {
  await initMonacoEditor();
});

watch([content, schema], () => {
  initMonacoEditor();
});
</script>

<style scoped lang="scss">
.game-config-editor {
  > .actions {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;

    > .button:first-child {
      margin-right: 2.5rem;
    }
  }

  > .schema-editor {
    display: flex;
    margin-top: 1rem;
    gap: 3rem;

    > .types {
      code {
        padding: 1rem;
        background-color: var(--color-gull-gray-200);
      }
    }

    > .editor {
      flex: 1;

      > .monaco {
        height: 600px;
        width: 100%;
        border: 2px solid var(--color-stone-300);
      }

      > .markers {
        display: flex;
        flex-direction: column;
        gap: 0.25em;
        margin-top: 1em;
        color: #e28729ff;
        font-size: 14px;
        font-family: 'Courier New', monospace;
        font-weight: bold;
      }
    }
  }
}
</style>
