import { injectionKey } from '~/common/inject';
import { AppData } from '@shared/app';
import { computed, ComputedRef } from 'vue';
import { UserDto } from '@shared/user';
import { Locale } from '@shared/i18n';
import { LicenceDefinitionDto, UserLicenceDto } from '@shared/licence';

export const AppDataInjectionKey = injectionKey<{
  data: AppData;
  user: ComputedRef<UserDto | null>;
  licence: ComputedRef<UserLicenceDto | null>;
  licenceDefinitions: ComputedRef<LicenceDefinitionDto[]>;
  locale: ComputedRef<Locale>;
  isLoggedIn: ComputedRef<boolean>;
}>();

export function useAppData(el: HTMLElement) {
  const data: AppData = JSON.parse(el.getAttribute('data-app') ?? '{}');

  const user = computed<UserDto | null>(() => data.currentUser || null);

  const licence = computed<UserLicenceDto | null>(
    () => data.userLicence || null,
  );

  const licenceDefinitions = computed<LicenceDefinitionDto[]>(
    () => data.licenceDefinitions ?? [],
  );

  const locale = computed(() => data.locale ?? Locale.De);

  const isLoggedIn = computed((): boolean => !!user.value);

  return {
    data,
    user,
    licence,
    licenceDefinitions,
    locale,
    isLoggedIn,
  };
}
