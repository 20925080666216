import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('./WebAdmin.vue'),
    meta: {
      layout: 'admin',
    },
  },
];

export default routes;
