import { createI18n } from 'vue-i18n';
import globalMessagesDe from './global-messages.de';
import commonMessagesDe from '../common/i18n/common.de';
import cockpitMessagesDe from '../cockpit/i18n/cockpit.de';

export default function setupI18n(locale: 'de' | undefined) {
  return createI18n({
    legacy: false,
    locale: locale || 'de',
    fallbackLocale: 'de',
    runtimeOnly: false,
    messages: {
      en: {
        // global: globalMessagesEn,
        // cockpit: cockpitMessagesEn,
        // common: commonMessagesEn,
        global: globalMessagesDe,
        cockpit: cockpitMessagesDe,
        common: commonMessagesDe,
      },
      de: {
        global: globalMessagesDe,
        cockpit: cockpitMessagesDe,
        common: commonMessagesDe,
      },
      fr: {
        // global: globalMessagesFr,
        // cockpit: cockpitMessagesFr,
        // common: commonMessagesFr,
        global: globalMessagesDe,
        cockpit: cockpitMessagesDe,
        common: commonMessagesDe,
      },
    },
    datetimeFormats: {},
  });
}
