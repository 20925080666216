import { GameStructureDto } from '@shared/game';
import useApi from '~/common/useApi';
import {
  GroupsOverviewPageDto,
  StudentsOverviewPageDto,
  GroupDto,
  MissionsOverviewPageDto,
  MissionDto,
  CreateGroupDto,
  CreateStudentDto,
  StudentDto,
  CreateMissionDto,
  UpdateStudentDto,
  StudentDetailPageDto,
  GroupDetailPageDto,
  MissionDetailPageDto,
  UpdateGroupDto,
  UpdateMissionDto,
  MissionSlot,
  StudentLimitDto,
} from '@shared/cockpit';
import { RegisterTeacherDto, UpdateTeacherDto, UserDto } from '@shared/user';
import { ChangeLocaleDto } from '@shared/app';
import { ExtendedUserLicenceDto } from '@shared/licence';

export default function useCockpitRepository() {
  function changeLocale(body: ChangeLocaleDto): Promise<object> {
    const api = useApi<object>(`cockpit/change-locale`, { baseUrl: '/' });

    return api.post({ body });
  }

  /* teacher */
  function registerTeacher(body: RegisterTeacherDto): Promise<object> {
    const api = useApi<object>(`cockpit/register-teacher`, { baseUrl: '/' });

    return api.post({ body });
  }

  function updateTeacher(body: UpdateTeacherDto): Promise<UserDto> {
    const api = useApi<UserDto>(`cockpit/user`);

    return api.put({ body });
  }

  /* games structure */
  function fetchGameStructure() {
    const api = useApi<GameStructureDto>('cockpit/game-structure');
    return api.get();
  }

  /* groups */
  function fetchGroups() {
    const api = useApi<GroupDto[]>('cockpit/groups');
    return api.get();
  }

  function fetchGroupsOverviewPage() {
    const api = useApi<GroupsOverviewPageDto>('cockpit/page/groups-overview');
    return api.get();
  }

  function createGroup(body: CreateGroupDto): Promise<GroupDto> {
    const api = useApi<GroupDto>(`cockpit/group`);
    return api.post({ body });
  }

  function withGroup(groupId: string) {
    function fetchGroupDetailPage() {
      const api = useApi<GroupDetailPageDto | null>(
        `cockpit/page/group/${groupId}`,
      );
      return api.get();
    }

    function updateGroup(body: UpdateGroupDto) {
      const api = useApi<GroupDto>(`cockpit/group/${groupId}`);
      return api.put({ body });
    }

    function assignMissionToGroup(missionId: string) {
      const api = useApi<object[]>(
        `cockpit/mission/${missionId}/assign-to-group/${groupId}`,
      );
      return api.post();
    }

    function deleteGroup() {
      const api = useApi<boolean>(`cockpit/group/${groupId}`);
      return api.delete();
    }

    return {
      fetchGroupDetailPage,
      updateGroup,
      deleteGroup,
      assignMissionToGroup,
    };
  }

  /* students */
  function fetchStudentsOverviewPage() {
    const api = useApi<StudentsOverviewPageDto>(
      `cockpit/page/students-overview`,
    );

    return api.get();
  }
  function fetchStudentLimit() {
    const api = useApi<StudentLimitDto>(`cockpit/student-limit`);

    return api.get();
  }

  function createStudent(body: CreateStudentDto) {
    const api = useApi<StudentDto>(`cockpit/student`);
    return api.post({ body });
  }

  function moveStudentsToGroup(groupId: string, studentIds: string[]) {
    const api = useApi<MissionsOverviewPageDto>(
      `cockpit/students/change-group/${groupId}`,
    );
    return api.post({
      body: { studentIds },
    });
  }

  function withStudent(studentId: string) {
    function fetchStudentDetailPage() {
      const api = useApi<StudentDetailPageDto | null>(
        `cockpit/page/student/${studentId}`,
      );
      return api.get();
    }

    function fetchStudent() {
      const api = useApi<StudentDto | null>(`cockpit/student/${studentId}`);
      return api.get();
    }

    function updateStudent(body: UpdateStudentDto) {
      const api = useApi<StudentDto>(`cockpit/student/${studentId}`);
      return api.put({ body });
    }

    function deleteStudent() {
      const api = useApi<boolean>(`cockpit/student/${studentId}`);
      return api.delete();
    }

    function activateStudent() {
      const api = useApi<StudentDto>(`cockpit/student/${studentId}/activate`);
      return api.put();
    }

    function deactivateStudent() {
      const api = useApi<StudentDto>(`cockpit/student/${studentId}/deactivate`);
      return api.put();
    }

    function regenerateAppCode() {
      const api = useApi<StudentDto>(
        `cockpit/student/${studentId}/regenerate-auth-code`,
      );
      return api.post();
    }

    function assignMissionToStudent(missionId: string, slot: MissionSlot) {
      const api = useApi<object>(
        `cockpit/mission/${missionId}/assign-to-student/${studentId}`,
      );
      return api.post({ body: { slot } });
    }

    function removeMissionFromStudent(missionId: string) {
      const api = useApi<object>(
        `cockpit/mission/${missionId}/remove-from-student/${studentId}`,
      );
      return api.post();
    }

    return {
      fetchStudentDetailPage,
      fetchStudent,
      deleteStudent,
      updateStudent,
      assignMissionToStudent,
      removeMissionFromStudent,
      activateStudent,
      deactivateStudent,
      regenerateAppCode,
    };
  }

  /* missions */
  function fetchMissionsOverviewPage() {
    const api = useApi<MissionsOverviewPageDto>(
      'cockpit/page/missions-overview',
    );
    return api.get();
  }

  function fetchMissions() {
    const api = useApi<MissionDto[]>('cockpit/missions');
    return api.get();
  }

  function createMission(body: Partial<CreateMissionDto>) {
    const api = useApi<MissionDto>('cockpit/mission');
    return api.post({ body });
  }

  function assignMissionToTeachersStudents(missionId: string) {
    const api = useApi<StudentDto[]>(
      `cockpit/mission/${missionId}/assign-to-students`,
    );
    return api.post();
  }

  function withMission(missionId: string) {
    function fetchMissionDetailPage() {
      const api = useApi<MissionDetailPageDto | null>(
        `cockpit/page/mission/${missionId}`,
      );
      return api.get();
    }

    function deleteMission() {
      const api = useApi<boolean>(`cockpit/mission/${missionId}`);
      return api.delete();
    }

    function removeMissionFromTeachersStudents() {
      const api = useApi<MissionDto>(
        `cockpit/mission/${missionId}/remove-from-students`,
      );
      return api.post();
    }

    function updateMission(body: UpdateMissionDto) {
      const api = useApi<MissionDto>(`cockpit/mission/${missionId}`);
      return api.put({ body });
    }

    return {
      fetchMissionDetailPage,
      deleteMission,
      removeMissionFromTeachersStudents,
      updateMission,
    };
  }

  function fetchExtendedUserLicenceInfo() {
    const api = useApi<ExtendedUserLicenceDto>(
      `cockpit/extended-user-licence-info`,
    );
    return api.get();
  }

  return {
    updateTeacher,
    fetchGroups,
    fetchGroupsOverviewPage,
    withGroup,
    fetchStudentsOverviewPage,
    fetchGameStructure,
    fetchMissionsOverviewPage,
    fetchMissions,
    createGroup,
    createStudent,
    createMission,
    withStudent,
    registerTeacher,
    withMission,
    assignMissionToTeachersStudents,
    changeLocale,
    fetchStudentLimit,
    moveStudentsToGroup,
    fetchExtendedUserLicenceInfo,
  };
}
