import './style/style.scss';
import * as Sentry from '@sentry/vue';
import VueApp from './App.vue';
import { createApp, App } from 'vue';
import routesCommon from '~/common/routes';
import routesAdmin from '~/admin/routes';
import routesCockpit from '~/cockpit/routes';
import routesContentEditor from '~/content-editor/routes';
import { createRouter, createWebHistory } from 'vue-router';
import setupI18n from '~/i18n';
import { AppDataInjectionKey, useAppData } from '~/common/useAppData';
import useGameStructure, {
  GameStructureInjectionKey,
} from '~/cockpit/useGameStructure';

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    ...routesCommon,
    ...routesAdmin,
    ...routesCockpit,
    ...routesContentEditor,
  ],
  linkActiveClass: '-active-link',
  linkExactActiveClass: '-exact-active-link',
});

const root = document.querySelector('#app') as HTMLElement;
const appData = useAppData(root);
const app = createApp(VueApp);
const gameStructure = useGameStructure(appData.user);

if (appData.data.env !== 'development') {
  startSentry(app, appData.data.instance);
}

app
  .use(router)
  .use(setupI18n('de'))
  .provide(AppDataInjectionKey, appData)
  .provide(GameStructureInjectionKey, gameStructure)
  .mount('#app');

function startSentry(app: App, instance: string | undefined) {
  Sentry.init({
    app,
    dsn: 'https://62c8885bb0db40a48810b9abb4030288@o307906.ingest.sentry.io/4504599883939840',
    environment: `${instance ?? 'local'}-frontend`,
  });
}
