export class BusinessError {
  constructor(public context: BusinessErrorContext, public message: string) {}
}

export type ResultOrError<TResult> = [TResult, null] | [null, BusinessError];

export type ResultOrErrorPromise<TResult> = Promise<ResultOrError<TResult>>;

export function result<T>(result: T): [T, null] {
  return [result, null];
}

export function error(context: BusinessError): [null, BusinessError] {
  return [null, context];
}

export function errorMessage(error: BusinessError | Error) {
  return error instanceof BusinessError
    ? error.message
    : "common.error.unknown";
}

export type UnknownError = {
  type: "unknown-error";
  cause: any;
};

export type LicenceUserNotFound = {
  type: "licence-user-not-found";
};

export type LicenceAvailabilityCheckError = {
  type: "licence-availability-check-error";
  status: "used" | "expired" | "not-started" | "invalid";
  licenceStartDate?: string;
};

export type LicenceActivateError = {
  type: "licence-activate-error";
  cause: "invalidCredentials" | "userAlreadyHasLicence";
};

export type BusinessErrorContext =
  | UnknownError
  | LicenceUserNotFound
  | LicenceAvailabilityCheckError
  | LicenceActivateError;
