import { provide, ref } from 'vue';
import { injectionKey } from '~/common/inject';

export const GameDetailInjectionKey = injectionKey<{
  selectGameToView: (gameId: number | null) => void;
}>();

export function useGameDetailModal() {
  const selectedGameToView = ref<number | null>(null);

  function selectGameToView(gameId: number | null) {
    selectedGameToView.value = gameId;
  }

  provide(GameDetailInjectionKey, {
    selectGameToView,
  });

  return {
    selectedGameToView,
    selectGameToView,
  };
}
