<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
  >
    <path
      d="M3.68765 4.60957C3.84778 4.80973 4.15222 4.80973 4.31235 4.60957L7.4801 0.649878C7.68962 0.387973 7.50315 0 7.16775 0H0.83225C0.496848 0 0.310378 0.387972 0.519902 0.649878L3.68765 4.60957Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
