import { onMounted, onUnmounted } from 'vue';

export function useEscKeydownListener(callback: () => void) {
  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      callback();
    }
  }

  function addEscKeydownListener() {
    window.addEventListener('keydown', onKeyDown);
  }
  function removeEscKeydownListener() {
    window.addEventListener('keydown', onKeyDown);
  }

  onMounted(() => {
    addEscKeydownListener();
  });

  onUnmounted(() => {
    removeEscKeydownListener();
  });
}
