<template>
  <Teleport v-if="game" to="body">
    <div v-show="isOpen" class="app-modal">
      <dialog
        ref="dialog"
        :open="isOpen"
        role="alertdialog"
        class="modal modal-game"
      >
        <IconButton
          type="button"
          class="close"
          icon-name="x-mark"
          :label="t('cockpit.general.closeModal')"
          @click="closeModal()"
        />
        <div class="content">
          <div class="moduletitle">{{ game.moduleName }}</div>
          <div class="gametitle">{{ game.game.name }}</div>

          <div class="instructions">
            <div class="title">
              {{ t('cockpit.games.detail.instructions') }}
            </div>
            <div class="texts">
              <div
                v-for="(instruction, index) in game.game.instructions ?? []"
                :key="`${gameId}-instruction-${index}`"
                class="text"
              >
                {{ instruction }}
              </div>
            </div>
          </div>

          <div
            v-if="game.game.teacherInstructions?.length ?? 0 > 0"
            class="instructions"
          >
            <div class="title">
              {{ t('cockpit.games.detail.teacherInstructions') }}
            </div>
            <div class="texts">
              <div
                v-for="(instruction, index) in game.game.teacherInstructions ??
                []"
                :key="`${gameId}-instruction-${index}`"
                class="text"
              >
                {{ instruction }}
              </div>
            </div>
          </div>

          <div class="images">
            <div class="imagewrapper">
              <img
                class="image"
                :src="`/images/game/G${gameId
                  ?.toString()
                  .padStart(3, '0')}_1_large.webp`"
              />
            </div>
            <div class="imagewrapper">
              <img
                class="image"
                :src="`/images/game/G${gameId
                  ?.toString()
                  .padStart(3, '0')}_2_large.webp`"
              />
            </div>
          </div>
        </div>

        <div class="modalactions">
          <ButtonSecondary
            class="button"
            type="button"
            :label="t('global.close')"
            @click="closeModal()"
          ></ButtonSecondary>
        </div>
      </dialog>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import ButtonSecondary from '~/common/buttons/ButtonSecondary.vue';
import { useEscKeydownListener } from '~/common/useEscKeydownListener';
import useModalFocus from '~/common/useModalFocus';
import { injectStrict } from '~/common/inject';
import { GameStructureInjectionKey } from '~/cockpit/useGameStructure';
import IconButton from '~/common/buttons/IconButton.vue';

const { t } = useI18n();
const dialog = ref<HTMLElement | null>(null);

const props = defineProps<{
  gameId: number | null;
}>();

const emit = defineEmits(['close']);

const isOpen = computed(() => !!props.gameId);

function closeModal() {
  if (!isOpen.value) {
    return;
  }

  emit('close');
}

const { findGameByIdWithModuleAndTopic } = injectStrict(
  GameStructureInjectionKey,
);

const game = computed(() =>
  props.gameId ? findGameByIdWithModuleAndTopic(props.gameId) : null,
);

useEscKeydownListener(closeModal);
useModalFocus(dialog, isOpen);
</script>

<style scoped lang="scss">
.modal-game {
  width: 50rem;
  padding: 3.5rem;

  > .content {
    > .moduletitle {
      @include text-md();
    }

    > .gametitle {
      @include text-xl();
    }

    > .instructions {
      padding-top: 1.5rem;

      > .title {
        @include text-lg();
      }
    }

    > .images {
      padding: 2.5rem 0;
      display: flex;
      gap: 1rem;

      > .imagewrapper {
        > .image {
          border-radius: 5px;
        }
      }
    }
  }

  > .modalactions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
