import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/cockpit',
    name: 'Cockpit',
    component: () => import('./WebCockpit.vue'),
    redirect: { name: 'OverviewStudents' },
    children: [
      {
        path: 'register',
        name: 'TeacherRegistration',
        component: () => import('./teacher-registration/RegistrationView.vue'),
      },
      {
        path: 'groups',
        name: 'OverviewGroups',
        component: () => import('./groups/OverviewGroups.vue'),
      },
      {
        path: 'groups/:id',
        name: 'GroupDetail',
        component: () => import('./groups/GroupDetail.vue'),
      },
      {
        path: 'groups/add',
        name: 'GroupCreate',
        component: () => import('./groups/CreateGroup.vue'),
      },
      {
        path: 'games',
        name: 'OverviewGames',
        component: () => import('./games/OverviewGames.vue'),
      },
      {
        path: 'missions',
        name: 'OverviewMissions',
        component: () => import('./missions/OverviewMissions.vue'),
      },
      {
        path: 'missions/:id',
        name: 'MissionDetail',
        component: () => import('./missions/MissionDetail.vue'),
      },
      {
        path: 'missions/add',
        name: 'CreateMission',
        component: () => import('./missions/CreateMission.vue'),
      },
      {
        path: 'missions/:id/edit',
        name: 'EditMission',
        component: () => import('./missions/EditMission.vue'),
      },
      {
        path: 'students',
        name: 'OverviewStudents',
        component: () => import('./students/OverviewStudents.vue'),
      },
      {
        path: 'students/:id',
        name: 'StudentDetail',
        component: () => import('./students/StudentDetail.vue'),
      },
      {
        path: 'students/create',
        name: 'StudentCreate',
        component: () => import('./students/CreateStudent.vue'),
      },
      {
        path: 'students/activation',
        name: 'StudentActivation',
        component: () => import('./students/StudentActivation.vue'),
      },
      {
        path: 'profile',
        name: 'ProfileOverview',
        component: () => import('./profile/ProfileOverview.vue'),
      },
      {
        path: 'licence',
        name: 'LicenceView',
        component: () => import('./licence/LicenceView.vue'),
      },
      {
        path: 'welcome',
        name: 'WelcomeScreen',
        component: () => import('./welcome/WelcomeScreen.vue'),
      },
      {
        path: 'activate-licence',
        name: 'ActivateLicence',
        component: () => import('./licence/ActivateLicence.vue'),
      },
      {
        path: 'licence-invalid',
        name: 'LicenceInvalid',
        component: () => import('./licence/LicenceInvalid.vue'),
      },
      {
        // callback route -> redirect is used after user has activated licence on licence plattform
        path: 'licence/activate',
        name: 'LicenceActivateCallbackRoute',
        redirect: { name: 'LicenceView', query: {} },
      },
      {
        path: '/:pathMatch(.*)',
        name: 'ErrorView',
        component: () => import('./errors/ErrorView.vue'),
      },
    ],
  },
];

export default routes;
