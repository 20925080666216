import { ToastNotificationDto } from '@shared/app';

export type ToastNotification = Omit<ToastNotificationDto, 'target'>;

export function useToast(notification: ToastNotification) {
  const event = getToastEvent(notification);
  window.dispatchEvent(event);
}
function getToastEvent(notification: ToastNotification) {
  return new CustomEvent<ToastNotification>('toast', {
    detail: {
      message: notification.message,
      type: notification.type,
    },
  });
}
