<template>
  <div class="app-layout -content-editor">
    <header class="content-editor-header">
      <h1 class="title">Appolino Games Configurator</h1>
      <form class="logout-form" action="/auth/logout" method="POST">
        <input type="hidden" name="_token" :value="csrfToken" />
        <ButtonPrimary type="submit" icon-name="user" label="Logout" />
      </form>
    </header>
    <main class="cockpit-main">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
import useCsrf from '~/common/forms/useCsrf';
import ButtonPrimary from '~/common/buttons/ButtonPrimary.vue';

const { token: csrfToken } = useCsrf();
</script>

<style lang="scss">
.app-layout.-content-editor {
  padding: 2rem var(--base-component-padding-inline);
}

.content-editor-header {
  display: flex;
  height: 5.375rem;
  justify-content: space-between;

  > .title {
    @include text-2xl();
  }
}

.content-editor-main {
  @include text-md();

  padding: 2rem 0;
}
</style>
