import { injectionKey } from '~/common/inject';
import useCockpitRepository from '~/cockpit/useCockpitRepository';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';
import {
  Difficulty,
  GameDto,
  GameStructureDto,
  ModuleDto,
  TopicDto,
  World,
} from '@shared/game';
import { UserDto } from '@shared/user';
import { UserRole } from '@shared/auth';

export interface GameWithModule {
  game: GameDto;
  moduleName: string;
}

export interface GameWithModuleAndTopic {
  game: GameDto;
  moduleName: string;
  topicName: string;
}

export interface GameFlat {
  world: World;
  difficulty: Difficulty;
  topic: string;
  module: string;
  id: number;
  game: GameDto;
}

export interface GameStructureInjection {
  gameStructure: Ref<GameStructureDto | null>;
  worlds: ComputedRef<{ name: string; id: World }[]>;
  topics: ComputedRef<TopicDto[]>;
  modules: ComputedRef<ModuleDto[]>;
  games: ComputedRef<GameDto[]>;
  gamesWithModule: ComputedRef<GameWithModule[]>;
  gamesFlat: ComputedRef<GameFlat[]>;
  gamesWithModuleAndTopic: ComputedRef<GameWithModuleAndTopic[]>;
  findGamesByTopics: (topicNames: string[]) => GameWithModule[];
  findGameById: (id: number) => GameDto | null;
  findGameByIdWithModule: (id: number) => GameWithModule | null;
  findGameByIdWithModuleAndTopic: (id: number) => GameWithModuleAndTopic | null;
  findGameByName: (name: string) => GameDto | null;
  getGamesWithModule: (ids: number[]) => GameWithModule[];
  filteredGamesWithModule: () => {
    filteredByWorld: (games: GameWithModule[]) => GameWithModule[];
  };
  findTopicsByWorld: (worldId: World) => TopicDto[];
}

export const GameStructureInjectionKey = injectionKey<GameStructureInjection>();

export default function useGameStructure(
  user: ComputedRef<UserDto | null>,
): GameStructureInjection {
  const { fetchGameStructure } = useCockpitRepository();

  const gameStructure = ref<GameStructureDto | null>(null);

  const worlds = computed(() => {
    if (!gameStructure.value) {
      return [];
    }
    const worlds: { name: string; id: World }[] = [];
    for (const w of gameStructure.value.worlds) {
      worlds.push({
        name: w.name,
        id: w.id,
      });
    }
    return worlds;
  });

  const topics = computed((): TopicDto[] => {
    if (!gameStructure.value) {
      return [];
    }
    const t: TopicDto[] = [];
    for (const w of gameStructure.value.worlds) {
      t.push(...w.topics);
    }
    return t;
  });

  const modules = computed((): ModuleDto[] => {
    const m: ModuleDto[] = [];
    for (const t of topics.value) {
      m.push(...t.modules);
    }
    return m;
  });

  const games = computed((): GameDto[] => {
    const g: GameDto[] = [];
    for (const m of modules.value) {
      g.push(...m.games);
    }
    return g;
  });

  const gamesWithModule = computed((): GameWithModule[] => {
    const g: GameWithModule[] = [];
    for (const m of modules.value) {
      g.push(
        ...m.games.map((g) => {
          return {
            game: g,
            moduleName: m.name,
          };
        }),
      );
    }
    return g;
  });

  const gamesWithModuleAndTopic = computed((): GameWithModuleAndTopic[] => {
    const g: GameWithModuleAndTopic[] = [];
    for (const t of topics.value) {
      for (const m of t.modules) {
        g.push(
          ...m.games.map((g) => {
            return {
              game: g,
              moduleName: m.name,
              topicName: t.name,
            };
          }),
        );
      }
    }
    return g;
  });

  const gamesFlat = computed(() => {
    const games: GameFlat[] = [];

    gameStructure.value?.worlds.forEach((w) => {
      w.topics.forEach((t) => {
        const topicName = t.name;

        t.modules.forEach((m) => {
          const moduleName = m.name;

          m.games.forEach((g) => {
            games.push({
              world: w.id as World,
              topic: topicName,
              module: moduleName,
              game: g,
              difficulty: m.difficulty,
              id: g.id,
            });
          });
        });
      });
    });

    return games;
  });

  const hasUserTeacherRole = computed(() => {
    return user.value && user.value?.roles.includes(UserRole.Teacher);
  });

  if (hasUserTeacherRole.value) {
    loadGames();
  }

  watch(hasUserTeacherRole, (isTeacher) => {
    if (isTeacher) {
      loadGames();
    }
  });

  return {
    gameStructure,
    topics,
    modules,
    games,
    gamesWithModule,
    gamesWithModuleAndTopic,
    gamesFlat,
    worlds,
    findTopicsByWorld,
    findGamesByTopics,
    findGameById,
    findGameByIdWithModule,
    findGameByName,
    findGameByIdWithModuleAndTopic,
    getGamesWithModule,
    filteredGamesWithModule,
  };

  async function loadGames() {
    gameStructure.value = await fetchGameStructure();
  }

  function findGameByName(name: string): GameDto | null {
    return games.value.find((g) => g.name === name) || null;
  }

  function findGameById(id: number): GameDto | null {
    return games.value.find((g) => g.id === id) || null;
  }

  function findGameByIdWithModule(id: number): GameWithModule | null {
    return gamesWithModule.value.find((g) => g.game.id === id) || null;
  }

  function findGameByIdWithModuleAndTopic(
    id: number,
  ): GameWithModuleAndTopic | null {
    return gamesWithModuleAndTopic.value.find((g) => g.game.id === id) || null;
  }

  function findGamesByTopics(topicNames: string[]): GameWithModule[] {
    const filteredGames =
      gamesWithModuleAndTopic.value.filter((g) =>
        topicNames.includes(g.topicName),
      ) || [];
    return filteredGames.map((g) => ({
      game: g.game,
      moduleName: g.moduleName,
    }));
  }

  function getGamesWithModule(gameIds: number[]) {
    const g = gameIds.map((id) => findGameByIdWithModule(id) as GameWithModule);
    return g.filter((i) => !!i);
  }

  function filteredGamesWithModule() {
    return { filteredByWorld };

    function filteredByWorld(games: GameWithModule[]) {
      // console.log(games);
      return games;
    }
  }

  function findTopicsByWorld(worldId: World) {
    if (!gameStructure.value) {
      return [];
    }

    const w = gameStructure.value.worlds.find((w) => w.id === worldId);

    return w?.topics ?? [];
  }
}
