<template>
  <div class="router-view">
    <RouterView />
    <ToastsBar />
    <ModalGame :game-id="selectedGameToView" @close="selectGameToView(null)" />
  </div>
</template>

<script setup lang="ts">
import ToastsBar from '~/common/ToastsBar.vue';
import { injectStrict } from '~/common/inject';
import { AppDataInjectionKey } from '~/common/useAppData';
import { useI18n } from 'vue-i18n';
import ModalGame from '~/cockpit/games/components/ModalGame.vue';
import { useGameDetailModal } from '~/cockpit/games/useGameDetailModal';

const { locale } = injectStrict(AppDataInjectionKey);

const i18n = useI18n();

i18n.locale.value = locale.value;

const { selectedGameToView, selectGameToView } = useGameDetailModal();
</script>
