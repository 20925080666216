import { nextTick, onMounted, Ref, watch } from 'vue'

// focus element when dialog is opened
export default function useModalFocus(modalEl: Ref<HTMLElement | null>, isOpen: Ref<boolean>) {

  onMounted(() => {
    watch(isOpen, (newValue) => {
      if(newValue) {
        nextTick(() => {
          modalEl?.value?.focus();
        });
      }
    });
  });

}