import { RouteRecordRaw } from 'vue-router';
import WebContentEditor from '~/content-editor/WebContentEditor.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/content-editor',
    name: 'ContentEditor',
    component: WebContentEditor,
  },
];

export default routes;
