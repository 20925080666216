import { GameConfigDto } from '@shared/game';
import { ContentEditorGamesDataDto } from '@shared/content-editor';
import useCsrf from '~/common/forms/useCsrf';

export default function useContentEditorRepository() {
  const { token } = useCsrf();
  async function getGamesData(): Promise<ContentEditorGamesDataDto> {
    const response = await fetch('/api/content-editor/games-data');
    return response.json();
  }

  async function saveGameConfig(gameConfig: GameConfigDto) {
    return fetch('/api/content-editor/save-game-config', {
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': token.value,
      },
      method: 'POST',
      body: JSON.stringify(gameConfig),
    });
  }

  return {
    getGamesData,
    saveGameConfig,
  };
}
