<template>
  <ContentEditorLayout class="web-content-editor">
    <div v-if="hasCorruptGameConfigs" class="info">
      <p>
        Achtung, es wurden fehlerhafte Game-Konfigurationen entdeckt. Bitte
        passe alle rot markierten Game-Konfigurationen an.
      </p>
    </div>
    <div
      v-if="gamesData && gamesData.gameSchemas.length"
      class="game-selection"
    >
      <label class="label" for="games-selector">Game wählen</label>
      <select id="games-selector" v-model="selectedGame">
        <optgroup v-for="g in groupedGames" :key="g.group" :label="g.group">
          <option v-for="game in g.games" :key="game.id" :value="game">
            <span>{{ game.id }} - {{ game.name }}</span>
            <span v-if="game.hasConfig">
              {{ game.isConfigValid ? ' ✅ ' : ' ⭕ ' }}</span
            >
          </option>
        </optgroup>
      </select>
    </div>
    <GameConfigEditor
      v-if="currentGameSchema"
      :schema="currentGameSchema"
      :content="currentGameConfig"
      @save="saveConfig($event)"
    />
  </ContentEditorLayout>
  <div v-if="gamesData && gamesData.gameSchemas.length === 0">
    Es wurden keine Schemas für die Game-Konfigurationen gefunden. Bitte
    <pre style="display: inline">npm run update-game-schemas</pre>
    ausführen.
  </div>
</template>

<script setup lang="ts">
import GameConfigEditor from '~/content-editor/GameConfigEditor.vue';
import { computed, onMounted, ref, Ref } from 'vue';
import { GameConfigDto, GameDto, ValidatedGameConfigDto } from '@shared/game';
import useContentEditorRepository from '~/content-editor/useContentEditorRepository';
import { ContentEditorGamesDataDto } from '@shared/content-editor';
import ContentEditorLayout from '~/content-editor/ContentEditorLayout.vue';

const { getGamesData, saveGameConfig } = useContentEditorRepository();

const gamesData: Ref<ContentEditorGamesDataDto | null> = ref(null);
const selectedGame: Ref<GameDto | null> = ref(null);

type GameDtoWithValidatedConfig = {
  isConfigValid: boolean | undefined;
  hasConfig: boolean;
} & GameDto;

interface GroupedGames {
  group: string;
  games: GameDtoWithValidatedConfig[];
}

const groupedGames = computed(() => {
  if (!gamesData.value?.gameStructure || !gamesData.value?.gameConfigs) {
    return [];
  }

  const groups: GroupedGames[] = [];

  gamesData.value.gameStructure.worlds.forEach((world) => {
    world.topics.forEach((topic) => {
      topic.modules.forEach((module) => {
        const group = `${world.name} > ${topic.name} > ${module.name}`;
        groups.push({
          group,
          games: module.games.map((g) =>
            checkGameWithConfig(g, findGameConfigForGameId(g.id)),
          ),
        });
      });
    });
  });
  return groups;
});

const currentGameSchema = computed(() => {
  if (selectedGame.value && gamesData.value?.gameSchemas) {
    const schemas = gamesData.value.gameSchemas;
    return schemas.find((s) => s.gameRef === selectedGame.value?.ref) ?? null;
  }
  return null;
});

const currentGameConfig = computed(() => {
  if (selectedGame.value && gamesData.value?.gameConfigs) {
    const gameConfigs = gamesData.value?.gameConfigs;
    const gameConfig = gameConfigs.find(
      (c) => c.gameId === selectedGame.value?.id,
    );
    return gameConfig ? JSON.stringify(gameConfig.config) : '{}';
  }
  return null;
});

const hasCorruptGameConfigs = computed(() => {
  if (!gamesData.value?.gameConfigs.length) return false;
  return !!gamesData.value.gameConfigs.some((c) => c.isValid === false);
});

onMounted(async () => {
  await loadGameData();
});

function findGameConfigForGameId(id: number) {
  return gamesData.value?.gameConfigs.find((c) => c.gameId === id) || null;
}

function checkGameWithConfig(
  game: GameDto,
  config: null | ValidatedGameConfigDto,
): {
  isConfigValid: boolean | undefined;
  hasConfig: boolean;
} & GameDto {
  const hasConfig = !!config;
  return {
    ...game,
    hasConfig,
    isConfigValid: hasConfig ? config.isValid : undefined,
  };
}

async function loadGameData() {
  gamesData.value = await getGamesData();
}

async function saveConfig(config: string) {
  if (!selectedGame.value) {
    return;
  }
  const gameConfigDto: GameConfigDto = {
    gameId: selectedGame.value.id,
    gameRef: selectedGame.value.ref,
    config: JSON.parse(config),
  };

  await saveGameConfig(gameConfigDto);
  await loadGameData();

  alert('Game Konfiguration gespeichert!');
}
</script>

<style scoped lang="scss">
.web-content-editor {
  > .info {
    padding: 0 0 2rem 0;
    color: var(--color-error);
  }
}

.game-selection {
  padding: 1rem;
  background: var(--color-gull-gray-200);
  margin-bottom: 5rem;
}

.label {
  display: inline-block;
  margin-right: 0.5rem;
}
</style>
