export default {
  hello: 'Hallo',
  student: 'SuS',
  students: 'SuS',
  filterPlaceholder: 'Suche...',
  search: 'Suche',
  difficulty: 'Schwierigkeit',
  cancel: 'Abbrechen',
  close: 'Schliessen',
  delete: 'Löschen',
  appCode: 'Lernenden-Code',
  yes: 'Ja',
  no: 'Nein',
  save: 'Speichern',
  noDataEntries: 'Keine Einträge vorhanden',
  learningWorld: 'Lernwelt',
  notifications: {
    generic: 'Etwas ist schief gelaufen',
    invalidRegistrationToken: 'Token falsch.',
    activatedUser: 'E-Mail Adresse erfolgreich bestätigt',
    failedLogin: 'Username oder Passwort nicht korrekt.',
  },
  forms: {
    errorMessages: {
      notEmpty: 'Wert darf nicht leer sein',
      invalidName: 'Muss mindestens 3 Zeichen enthalten',
      invalidUrl: 'Bitte korrekt URL eingeben',
      invalidEmail: 'Bitte gültige E-Mail-Adresse eingeben',
      invalidPassword:
        'Passwort muss aus mindestens 8 Zeichen bestehen. Davon mindestens eine Zahl, ein klein- und ein grossgeschriebener Buchstabe',
    },
  },
  missionsWorld: {
    math: 'Mathe',
    language: 'Sprache',
  },
  games: {
    difficulty: {
      easy: 'Einfach',
      medium: 'Mittel',
      hard: 'Schwer',
    },
    worlds: {
      math: {},
    },
  },
};
