<template>
  <div class="toasts-bar">
    <div
      v-for="n in notifications"
      :key="n.message"
      class="toast"
      :class="[`toast-${n.type}`]"
    >
      <ExclamationTriangleIcon v-if="n.type === 'error'" class="icon" />
      <CheckCircleIcon v-if="n.type === 'info'" class="icon" />
      <p class="text">
        {{ n.message }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ToastNotification } from '~/common/useToasts';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/vue/16/solid';

const timeout = 6000;

const notifications = ref<ToastNotification[]>([]);

// @ts-ignore
window.addEventListener('toast', onToastEvent);

function onToastEvent(e: CustomEvent<ToastNotification>) {
  createToast(e.detail);
}

function createToast(notification: ToastNotification) {
  notifications.value.push(notification);

  setTimeout(() => {
    const i = notifications.value.indexOf(notification);
    notifications.value.splice(i, 1);
  }, timeout);
}
</script>

<style scoped lang="scss">
.toasts-bar {
  position: fixed;
  inset: auto auto 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 101;
}
</style>
