export default {
  general: {
    actions: {
      assignMission: 'Zuweisen',
    },
    accordion: {
      open: 'Aufklappen',
      close: 'Zuklappen',
    },
    closeModal: 'Modal schliessen',
    table: {
      noResults: '0 Suchresultate gefunden',
    },
  },
  welcome: {
    introText:
      'Willkommen {name}, schön bist du da. Momentan ist hier noch nichts erfasst. In drei einfachen Schritten kannst du Appolino für deine SuS einsetzen.',
    actions: {
      createFirstGroup: 'Jetzt erste Gruppe und SuS erfassen',
      skip: 'Mache ich später, erstmal umschauen',
    },
    instructions: {
      step: 'Schritt {i}',
      step1: {
        title: 'Gruppe(n) erstellen',
        description:
          'SuS sind prinzipiell in Gruppen organisiert. Eine Gruppe kann eine Klasse oder eine sonstige Zusammenstellung sein. Gruppen sind nur für dich sichtbar und unterstützen dich bei deiner Organisation.',
      },
      step2: {
        title: 'SuS erfassen',
        description:
          'SuS werden von dir erfasst und verwaltet. Sie erhalten von dir einen Lernenden-Code, der sie identifiziert und mit dem sie sich in der App anmelden können. Dieser Lernenden-Code stellt sicher, dass die SuS Geräteunabhängig arbeiten können.',
      },
      step3: {
        title: 'Mission erstellen',
        description:
          'Du kannst optional deinen SuS bestimmte Games in fester oder freier Reihenfolge zur Bearbeitung als Auftrag geben. Deine SuS haben dann eine oder zwei Missionen, welche sie in deinem Sinne durch die vielen verfügbaren Games leiten. ',
      },
    },
  },
  groups: {
    linkTitle: 'Gruppen',
    backToOverview: 'Zurück zur Gruppen-Übersicht',
    overview: {
      title: 'Gruppen-Übersicht',
      titleInner: '{nStudents} SuS in {nGroups} Gruppen',
      titleInnerIfNoGroups: 'Noch keine Gruppen erfasst',
      actions: {
        createFirstGroup: 'Jetzt Gruppe erfassen',
        createGroup: 'Neue Gruppe erfassen',
        deleteGroup: '{name} löschen',
      },
      groupCard: {
        linkText: 'Details',
      },
    },
    create: {
      title: 'Neue Gruppe erstellen',
      eyebrow: 'Gruppen',
      form: {
        groupName: 'Gruppenname',
        submitText: 'Gruppe erstellen',
      },
      actions: {
        addStudent: 'SuS hinzufügen',
        removeStudent: 'SuS entfernen',
      },
      addStudents: {
        info: 'SuS zu Gruppe hinzufügen',
        feedbackStudentLimitReached:
          'SuS Limit erreicht. Es können keine weiteren Sus hinzugefügt werden.',
        description:
          'Du kannst SuS direkt hier erfassen oder sie später hinzufügen.',
      },
      successMessage: 'Gruppe "{name}" wurde erstellt',
    },
    detail: {
      titleInner: '{nStudents} SuS in dieser Gruppe',
      titleInnerIfNoStudents: '{nStudents} SuS in dieser Gruppe',
      actions: {
        addFirstStudent: 'Jetzt SuS hinzufügen',
        addStudent: 'SuS hinzufügen',
        editGroup: 'Name anpassen',
        moveAllStudentsToNewGroup: 'SuS verschieben',
        addMissionToAllStudents: 'Mission an alle zuweisen',
        printAllAppCodes: 'Alle Lernenden-Codes drucken',
        moveStudent: '{name} verschieben',
        deleteStudent: '{name} löschen',
      },
      tableHead: {
        name: 'Name',
        mission1: 'Mission 1',
        mission2: 'Mission 2',
        appCode: 'Lernenden-Code',
        remove: 'Entfernen',
        actions: 'Aktionen',
      },
      updateSuccessMessage: 'Gruppennamen wurde erfolgreich aktualisiert',
      moveStudents: {
        title: 'SuS verschieben',
        subtitle: 'Wähle die SuS aus, welche du verschieben willst.',
        submitText: 'Auswahl verschieben',
      },
    },
    modals: {
      moveStudentsToGroup: {
        hasGroups: {
          eyebrow: 'SuS Verschieben',
          title: 'In welche Gruppe willst du die Sus verschieben?',
          submitText: 'SuS verschieben',
          successMessage: 'SuS wurden in {newGroup} verschoben.',
        },
        hasNoGroups: {
          eyebrow: 'SuS Verschieben',
          title:
            'Momentan gibt es keine zweite Gruppe, in welche die SuS verschoben werden könnten. Bitte zuerst eine weitere Gruppe erfassen.',
          submitText: 'Neue Gruppe erfassen',
        },
      },
      moveStudentToGroup: {
        hasGroups: {
          eyebrow: 'SuS Verschieben',
          title: `In welche Gruppe willst du {name} verschieben?`,
          submitText: 'Verschieben',
          successMessage: `{name} wurden in {newGroup} verschoben.`,
        },
        hasNoGroups: {
          eyebrow: 'SuS Verschieben',
          title:
            'Momentan gibt es keine zweite Gruppe, in die {name} verschoben werden könnte. Bitte zuerst eine weitere Gruppe erfassen.',
          submitText: 'Neue Gruppe erfassen',
        },
      },
      deleteGroup: {
        eyebrow: 'Gruppe löschen',
        title: 'Willst du die Gruppe {name} wirklich entfernen?',
        description: 'Alle SuS dieser Gruppe werden ebenfalls gelöscht',
        submitText: 'Ja, unwiderruflich löschen',
      },
    },
  },
  students: {
    linkTitle: 'SuS',
    backToOverview: 'Zurück zur Studenten-Übersicht',
    overview: {
      title: 'Schüler-Übersicht',
      innerTitle: `{nStudents} / {nMaxStudents} SuS in {nGroups} Gruppen `,
      innerTitleIfNoStudents: 'Noch keine SuS erfasst',
      actions: {
        addFirstStudent: 'Jetzt SuS erfassen',
        addStudent: 'SuS hinzufügen',
        addMissionToAllStudents: 'Mission an alle zuweisen',
        printAllAppCodes: 'Alle Lernenden-Codes drucken',
        moveStudent: '{name} verschieben',
        deleteStudent: '{name} löschen',
      },
      tableHead: {
        name: 'Name',
        group: 'Gruppe',
        mission1: 'Mission 1',
        mission2: 'Mission 2',
        appCode: 'Lernenden-Code',
        remove: 'Entfernen',
        actions: 'Aktionen',
      },
    },
    create: {
      title: 'Neue SuS erfassen',
      eyebrow: 'SuS',
      form: {
        name: 'Name',
        group: 'Gruppe',
        newGroup: 'Name der neuen Gruppe',
        initialMission: 'Initiale Mission',
        submitText: 'SuS erstellen',
        successMessage: 'SuS wurde erfolgreich erstellt',
      },
      creteNewGroup: 'Neue Gruppe erstellen',
      feedbackLimitReached:
        'Es können keine weiteren SuS hinzugefügt werden. Um weitere SuS erstellen zu können, muss die Lizenz angepasst werden. Siehe dazu <a href="/cockpit/licence">Lizenz-Übersicht</a>.',
    },
    detail: {
      titleMissions: 'Missionen',
      titleLearningStatus: 'Lernstand',
      actions: {
        changeGroup: 'Gruppe wechseln',
        changeName: 'Name anpassen',
      },
      missionCard: {
        title: 'Mission',
        actions: {
          addMission: 'Mission hinzufügen',
          deleteMission: 'Mission löschen',
        },
      },
      modals: {
        deleteStudent: {
          eyebrow: 'SuS löschen',
          title: 'Willst du {name} wirklich entfernen?',
          description:
            'Der Lernstand und alle Missionsfortschritte von {name} werden unwiederruflich gelöscht. Der Lernenden-Code wird ungültig gemacht.',
          submitText: 'Ja, unwiderruflich löschen',
        },
        qrCode: {
          eyebrow: 'Lernenden-Code für',
          print: 'Drucken',
          regenerate: 'Neu generieren',
          printAll: 'Codes für {count} SuS drucken',
        },
      },
      updateSuccessMessage: 'Name wurde erfolgreich aktualisiert',
    },
    activation: {
      title: 'Aktivierung SuS',
      titleInner: '{nStudents} / {nMaxStudents} SuS',
      activated: 'Aktivierte SuS',
      deactivated: 'Deaktivierte SuS',
      activate: 'Aktivieren',
      deactivate: 'Deaktivieren',
    },
  },
  missions: {
    linkTitle: 'Missionen',
    backToOverview: 'Zurück zur Missions-Übersicht',
    overview: {
      title: 'Missions-Übersicht',
      titleInner: '{nMissions} Missionen',
      titleInnerIfNoMissions: 'Noch keine Mission erfasst',
      actions: {
        createFirstMission: 'Jetzt erste Mission erstellen',
        createMission: 'Neue Mission erstellen',
        deleteMission: 'Mission löschen',
      },
      sortTableBy: 'Sortieren nach {name}',
      tableHead: {
        name: 'Name',
        progress: 'Fortschritt der SuS',
        studentsAssigned: 'Zugewiesene SuS',
        games: 'Games',
        isFixedOrder: 'Fixe Reihenfolge',
        remove: 'Löschen',
      },
    },
    create: {
      title: 'Neue Mission erstellen',
      eyebrow: 'Missions-Editor',
      form: {
        inputs: {
          world: 'Lernwelt',
          name: 'Missionsname',
          order: 'Mission mit fester Reihenfolge der Games',
          games: 'Ausgewählte Games',
          gamesIfNoneSelected: 'Games von rechts hier hinziehen und platzieren',
        },
        submitText: 'Mission erstellen',
        removeGame: 'Game entfernen',
      },
      gamesOverview: {
        title: 'Verfügbare Games',
        ctaNewGame: 'Erstes Game platzieren',
        ctaNextGame: 'Nächstes Game platzieren',
        filterTopics: 'Topics',
        noGamesMatchFilters: 'Keine Games mit gesetzten Filtern.',
      },
    },
    edit: {
      title: 'Mission editieren',
      eyebrow: 'Missions-Editor',
      form: {
        inputs: {
          world: 'Lernwelt',
          name: 'Missionsname',
          order: 'Mission mit fester Reihenfolge der Games',
          games: 'Ausgewählte Games',
        },
        submitText: 'Mission speichern',
        removeGame: 'Game entfernen',
      },
      gamesOverview: {
        title: 'Verfügbare Games',
        ctaNewGame: 'Erstes Game platzieren',
        ctaNextGame: 'Nächstes Game platzieren',
      },
    },
    detail: {
      missionWithStudentsAssigned: {
        info: 'Diese Mission ist an mindestens eine/n SuS zugewiesen und kann deshalb momentan nicht mehr editiert werden. Um die Mission zu editieren, entferne sie bei allen SuS.',
      },
      missionWithNoStudentsAssigned: {
        info: 'Bisher wurden diese Mission keinen SuS zugewiesen.',
      },
      actions: {
        removeMissionFromAll: 'Mission von allen entfernen',
        removeMissionFromAllSuccess: 'Mission wurde von allen SuS entfernt',
        removeMissionFromStudent: 'Mission von {name} entfernen',
      },
      gamesInMission: 'Games dieser Mission',
      titleTable: 'Diesen SuS ist diese Mission zugeordnet',
      tableHead: {
        student: 'Name',
        progress: 'Fortschritt',
        remove: 'Entfernen',
      },
    },
    dropdown: {
      title: 'Mission',
      placeholder: 'Mission auswählen',
      none: 'Keine',
      ctaCreateMission: 'Jetzt neue Mission erstellen.',
      infoNoMissions: 'Es wurde noch keine Mission erstellt.',
      infoNoMissionsToSelect:
        'Es wurde noch keine Mission erstellt oder die vorhandene Mission ist bereits zugewiesen.',
    },
    modals: {
      assignMissionToAll: {
        eyebrow: 'Mission zuweisen',
        title: 'Wähle eine Mission aus, um sie allen SuS zuzuweisen.',
        submitText: 'Mission zuweisen',
        successMessage: 'Mission wurde allen SuS zugewiesen',
      },
      assignMissionToGroup: {
        eyebrow: 'Mission zuweisen',
        title:
          'Wähle eine Mission aus, um sie allen SuS der Gruppe “{groupName}” zuzuweisen.',
        infoDoubleAssignedStudent:
          'Obacht: Der/die SuS {studentName} hat bereits zwei Missionen zugewiesen – ihm/ihr wird diese Mission nicht zugewiesen.',
        infoDoubleAssignedStudents:
          'Obacht: Die SuS {studentNames} haben bereits zwei Missionen zugewiesen – ihnen wird diese Mission nicht zugewiesen.',
        submitText: '{count} SuS zuweisen',
        successMessage:
          'Mission wurde {count} SuS der Gruppe {name} zugewiesen',
      },
      assignMissionToStudent: {
        eyebrow: 'Mission zuweisen',
        title: 'Wähle eine Mission aus, um sie {name} zuzuweisen.',
        submitText: 'Mission zuweisen',
        successMessage: 'Mission "{missionName}" wurde {name} zugewiesen.',
      },
      removeMissionFromStudent: {
        eyebrow: 'Mission entfernen',
        title: 'Willst du "{missionName}" wirklich von {name} entfernen?',
        description:
          'Missionsfortschritte werden nicht gelöscht, das heisst wenn du die Mission später wieder an {name} zuweist, ist wieder der aktuelle Fortschritt vorhanden.',
        submitText: 'Ja, entfernen',
        successMessage: 'Mission wurde entfernt',
      },
      removeMissionFromAllStudents: {
        eyebrow: 'Mission von allen SuS entfernen',
        title: 'Willst du "{missionName}" wirklich von allen SuS entfernen?',
        description:
          'Missionsfortschritte werden nicht gelöscht, das heisst, wenn du die Mission später wieder an die SuS zuweist, ist wieder der aktuelle Fortschritt vorhanden.',
        submitText: 'Ja, von allen SuS entfernen',
        successMessage: 'Mission wurde von allen SuS entfernt',
      },
      deleteMission: {
        eyebrow: 'Mission löschen',
        title: 'Willst du “{name}” wirklich entfernen?',
        description: 'Die Konfiguration wird unwiderruflich gelöscht.',
        submitText: 'Ja, unwiderruflich löschen',
        successMessage: 'Mission wurde gelöscht.',
      },
    },
  },
  games: {
    linkTitle: 'Game-Übersicht',
    overview: {
      title: 'Game-Übersicht',
      options: {
        all: 'Alle Lernwelten',
        maths: 'Lernwelt Math',
        language: 'Lernwelt Sprache',
      },
      filters: {
        world: 'Lernwelt',
        difficulty: 'Schwierigkeit',
        topics: 'Topics',
      },
    },
    detail: {
      instructions: 'Anweisung für SuS',
      teacherInstructions: 'Hinweis für Lehrpersonen',
    },
  },
  navigationBar: {
    userInfos: {
      accountSettings: 'Kontoeinstellungen',
      licence: 'Lizenz',
      signOut: 'Abmelden',
    },
  },
  profile: {
    title: 'Kontoübersicht',
    saveData: 'Daten speichern',
    form: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      school: 'Stammschule',
      email: 'E-Mail',
      password: 'Neues Passwort',
      changePassword: 'Passwort ändern',
    },
    successMessage: 'Daten wurden gespeichert.',
  },
  registration: {
    header: {
      normal: {
        title: 'Testversion aktivieren',
        eyebrow: 'Appolino Cockpit',
      },
      onSuccess: {
        title: 'Registration erfolgreich',
        eyebrow: 'Appolino Cockpit',
      },
    },
    form: {
      firstname: 'Vorname',
      lastname: 'Nachname',
      school: 'Stammschule',
      email: 'E-Mail',
      password: 'Passwort',
      submitText: 'Registrieren',
    },
    successInfoText:
      'Du solltest in Kürze eine E-Mail an {email} erhalten. Dort findest du einen Aktivierungslink. Bitte klicke auf diesen, um dich einzuloggen und zu starten.',
    alreadyHaveAccount: 'Ich habe bereits ein Konto.',
    loginNow: 'Jetzt Einloggen',
  },
  licence: {
    title: 'Lizenz',
    purchase: {
      price: '{price} CHF pro SuS',
    },
    trial: {
      remainingDays: 'Noch {days} Tage testen',
      title: '30 Tage testen',
      price: 'Gratis',
    },
    info: {
      noLicence: 'Keine Lizenz vorhanden.',
      product: 'Mein Produkt',
      validUntil: 'Gültig bis {date} - noch {days} Tage',
      licenceKey: 'Lizenzschlüssel',
      admin: 'Lizenz gekauft durch',
      studentsLimit: 'Anzahl verwaltete SuS',
      buyMore: 'Zusätzliche Lizenzen kaufen',
      licenceCount: '{count} von {limit}',
      askLicenceAdmin:
        'Um zusätzliche Lizenzen zu erwerben, wende dich bitte an {name} (<a href="mailto:{email}">{email}</a>), der die Lizenzen gekauft hat.',
      studentLicenceAllocation: 'SuS-Lizenz-Zuteilung',
    },
    invalid: {
      infoText:
        'Deine Lizenz war gültig bis {date}. Deine Daten, sprich Gruppen, SuS und ihre Fortschritte sind weiterhin hier im Cockpit einsehbar, allerdings kannst du keine Änderungen mehr vornehmen. Die SuS die du verwaltest können sich nicht mehr ins Appolino einloggen. Sobald du eine neue Lizenz erwirbst bzw. aktivierst, werden alle Daten wieder freigeschaltet.',
      hasLicence: 'Du hast bereits eine Lizenz?',
      hasNoLicence: 'Noch keine Lizenz?',
      toLogin: 'Zum Login',
      status: {
        active: 'Aktiv',
        none: 'Keine Lizenz vorhanden',
        inactive: 'Lizenz ist nicht aktiv',
        expired: 'Lizenz ist abgelaufen',
      },
    },
    check: {
      status: {
        used: 'Lizenz wird bereits verwendet.',
        invalid: 'Lizenz Code falsch oder nicht gültig.',
        expired: 'Lizenz ist nicht mehr gültig.',
        notStarted: 'Lizenz wird erst am {startDate} aktiviert.',
      },
    },
    activate: {
      header: {
        eyebrow: 'Appolino Cockpit',
        title: 'Lizenz aktivieren',
      },
      list: {
        title: 'Noch keine Lizenz?',
      },
      errors: {
        invalidCredentials: 'User Anmeldung fehlgeschlagen.',
        userAlreadyHasLicence:
          'Dieser User hat bereits eine Lizenz aktivierte Lizenz.',
      },
      info: 'Falls du bereits eine Lizenz erhalten hast, kannst du diese hier einlösen. Alternativ kannst du unten auch direkt Lizenzen für dich oder andere Personen erwerben.',
      formCheckLicence: {
        input: 'Lizenzcode aktivieren',
        buttonReset: 'Lizenzcode ändern',
        submit: 'Lizenz überprüfen',
        feedbackIsValid:
          'Lizenzcode ist gültig. Mit welchem Konto soll die Lizenz verknüpft werden?',
      },
      formUserId: {
        input: 'E-Mail-Adresse',
        buttonReset: 'E-Mail-Adresse ändern',
        submit: 'Verknüpfen',
        feedbackUserExists:
          'Unter dieser E-Mail Adresse existiert bereits ein Account. Bitte entsprechendes Passwort eingeben.',
        feedbackUserNotExists:
          'Unter dieser E-Mail Adresse existiert noch kein Account. Neuer Account erstellen.',
        ctaActivateWithEdulog: {
          left: 'Lizenz mit',
          right: 'aktivieren',
        },
      },
      formLogin: {
        inputPassword: 'Passwort',
        submit: 'Lizenz aktivieren',
      },
      formRegister: {
        inputFirstname: 'Vorname',
        inputLastname: 'Nachname',
        inputPassword: 'Passwort',
        submit: 'Account erstellten & Lizenz aktivieren',
      },
    },
  },
};
