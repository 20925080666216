export default {
  or: 'oder',
  error: {
    unknown: 'Ein unbekannter Fehler ist aufgetreten.',
  },
  login: {
    header: {
      title: 'Appolino Cockpit',
      eyebrow: 'Zugang für Lehrpersonen',
    },
    getOrActiveLicence:
      'Ich hab noch kein Konto: Jetzt gratis testen, Lizenz aktivieren oder kaufen.',
    failedLogin: 'Login fehlgeschlagen: Username oder Passwort nicht korrekt',
    form: {
      email: 'E-Mail',
      password: 'Password',
      submitText: 'Login',
    },
    forgotPassword: 'Vergessen?',
    infoTextEmailActivated:
      'Ihre E-Mail Adresse wurde erfolgreich bestätigt. Sie können sich nun mit dem von Ihnen gesetzten Zugangsdaten ins Appolino Cockpit einloggen.',
    ctaLoginEdulog: 'Anmelden mit',
    failedLoginWithEdulog:
      'Login mit Edulog fehlgeschlagen. Stellen Sie sicher, dass eine Lizenz aktiviert wurde.',
  },
  forgotPassword: {
    header: {
      title: 'Passwort zurücksetzen',
      eyebrow: 'Appolino Cockpit',
    },
    titleSuccess: 'Link Versendet',
    infoSuccess:
      'Du solltest in kürze eine E-Mail bekommen. Dort findest du einen Link, um ein neues Passwort zu setzen. Falls es keinen Account gibt, der mit dieser E-Mail Adresse verbunden ist, wird keine E-Mal versandt.',
    infoText: 'E-Mail Adresse zum Zurücksetzen des Passworts eingeben',
    form: {
      email: 'E-Mail',
      submitText: 'Passwort zurücksetzen',
    },
    successMessage: 'Das Passwort wurde erfolgreich zurückgesetzt.',
    toLogin: 'Zurück zum Login',
  },
  resetPassword: {
    header: {
      title: 'Neues Passwort setzen',
      eyebrow: 'Appolino Cockpit',
    },
    isInvalidTitle: 'Token ungültig',
    isInvalidInfo:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    infoText: '(tbd) info text',
    form: {
      password: 'Neues Passwort',
      submitText: 'Passwort setzen',
    },
    successMessage: 'Ihr Passwort wurde erfolgreich geändert.',
  },
  auth: {
    unauthorized:
      'Sie sind nicht mehr eingeloggt, bitte melden Sie sich beim Cockpit an.',
    failedOther: 'Login fehlgeschlagen. Bitte versuchen Sie es erneut.',
  },
};
