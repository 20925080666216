import {
  ArrowRightEndOnRectangleIcon,
  PencilSquareIcon,
  UserCircleIcon,
  UserIcon,
  UserGroupIcon,
  UsersIcon,
  MagnifyingGlassIcon,
  ArrowUturnLeftIcon,
  QrCodeIcon,
  UserPlusIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  ArrowsRightLeftIcon,
  ChevronUpIcon,
  XCircleIcon,
  PlusIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ClipboardDocumentCheckIcon,
  ArrowLongRightIcon,
} from '@heroicons/vue/16/solid';
import {
  PrinterIcon as PrinterIconOutline,
  ArrowPathIcon as ArrowPathIconOutline,
} from '@heroicons/vue/24/outline';
import {
  Component,
  FunctionalComponent,
  HTMLAttributes,
  VNodeProps,
} from 'vue';
import CustomRocketIcon from '~/common/icons/CustomRocketIcon.vue';
import CustomArrowUpIcon from '~/common/icons/CustomArrowUpIcon.vue';
import CustomArrowDownIcon from '~/common/icons/CustomArrowDownIcon.vue';

export type HeroIconName =
  | 'arrow-right-on-rectangle'
  | 'pencil-square'
  | 'user-group'
  | 'user'
  | 'users'
  | 'magnifying-glass'
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'rocket'
  | 'trash'
  | 'qr-code'
  | 'user-plus'
  | 'uturn-left'
  | 'arrows-right-left'
  | 'x-circle'
  | 'x-mark'
  | 'plus'
  | 'plus-circle'
  | 'exclamation-triangle'
  | 'check-circle'
  | 'chevron-up'
  | 'user-circle'
  | 'arrow-path-outline'
  | 'printer-outline'
  | 'clipboard-document-check'
  | 'arrow-long-right';

export const heroIcons: Record<
  HeroIconName,
  FunctionalComponent<HTMLAttributes & VNodeProps> | Component
> = {
  'arrow-right-on-rectangle': ArrowRightEndOnRectangleIcon,
  'pencil-square': PencilSquareIcon,
  'user-circle': UserCircleIcon,
  'x-circle': XCircleIcon,
  'x-mark': XMarkIcon,
  user: UserIcon,
  users: UsersIcon,
  'user-group': UserGroupIcon,
  'magnifying-glass': MagnifyingGlassIcon,
  'qr-code': QrCodeIcon,
  rocket: CustomRocketIcon,
  'uturn-left': ArrowUturnLeftIcon,
  'arrow-up': CustomArrowUpIcon,
  'arrow-down': CustomArrowDownIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-right': ArrowRightIcon,
  'user-plus': UserPlusIcon,
  'arrows-right-left': ArrowsRightLeftIcon,
  trash: TrashIcon,
  'chevron-up': ChevronUpIcon,
  plus: PlusIcon,
  'plus-circle': PlusCircleIcon,
  'check-circle': CheckCircleIcon,
  'exclamation-triangle': ExclamationTriangleIcon,
  'printer-outline': PrinterIconOutline,
  'arrow-path-outline': ArrowPathIconOutline,
  'clipboard-document-check': ClipboardDocumentCheckIcon,
  'arrow-long-right': ArrowLongRightIcon,
};
